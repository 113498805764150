<template>
  <div>
    <ManageNews2 />
  </div>
</template>
<script>
import ManageNews2 from "@/components/News2/ManageNews2";
export default {
  components: {
    ManageNews2,
  },
  created() {},
};
</script>c